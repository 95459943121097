import { Routes, Route } from "react-router-dom";
import { isAuth } from "../helpers/cookies";
import { ROLES } from "../helpers/constants";

// admin routes
import DashboardView from "../pages/admin/Dashboard";
import ICategoryView from "../pages/admin/ICategory";
import CategoryView from "../pages/admin/Category";
import ProductTypesView from "../pages/admin/ProductTypes";
import ProductImageView from "../pages/admin/ProductImage";
import SupplierTypesView from "../pages/admin/SupplierTypes";
import CertifiedView from "../pages/admin/Certified";

// subadmin routes
import HomeView from "../pages/subadmin/Home";
import SuppliersView from "../pages/subadmin/Suppliers";
import ProductsView from "../pages/subadmin/Products";
import SupplierProductsView from "../pages/subadmin/SupplierProducts";
import SupplierProductsAddView from "../pages/subadmin/SupplierProductsAdd";
import HomePageCategory from "../pages/admin/HomePageCategory";
const AdminRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<DashboardView />} />
            <Route path="/dashboard" element={<DashboardView />} />
            <Route path="/icategory" element={<ICategoryView />} />
            <Route path="/category" element={<CategoryView />} />
            <Route path="/homepagecategory" element={<HomePageCategory />} />
            <Route path="/product-types" element={<ProductTypesView />} />
            <Route path="/product-image" element={<ProductImageView />} />
            <Route path="/supplier-types" element={<SupplierTypesView />} />
            <Route path="/certified" element={<CertifiedView />} />
            <Route path="/home" element={<HomeView />} />
            <Route path="/supplier/:status" element={<SuppliersView />} />
            <Route path="/products/:status" element={<ProductsView />} />
            <Route
                path="/products/:status/:supplier_id"
                element={<SupplierProductsView />}
            />
            <Route
                path="/products/:status/add/:supplier_id"
                element={<SupplierProductsAddView />}
            />
        </Routes>
    );
};

export default AdminRoutes;

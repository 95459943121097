import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Grid, Paper, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

import { defaultPaginate, fetchSupplierStatus } from "../../../helpers/constants";
import { SearchRecords } from "../../../components/Common/SearchRecords";
import { capitalizeFirstLetter } from "../../../helpers/utils";
import SuppliersTableComponent from "../../../components/subadmin/suppliers-manager/SuppliersTable";
import { useGetSupplierByIdQuery, useGetSuppliersQuery } from "../../../store/suppliers/suppliersApis";
import { SuppliersAddDrawer } from "../../../components/subadmin/suppliers-manager/SuppliersAddDrawer";

const SuppliersManagerContainer = (props) => {
    const { showDrawer, formType, initialValues, pagination, params } = props;
    const status = fetchSupplierStatus[params?.status] || "pending"
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [supplierId, setSupplierId] = React.useState(null);
    const [profileStatus, setProfileStatus] = React.useState("all");
    const { isLoading, data: suppliers, isFetching } = useGetSuppliersQuery({ ...pagination, status, profile_status: profileStatus });

    useEffect(() => {
        props.handlePaginationState(defaultPaginate);
    }, [status])

    const handlePagination = (setting) => {
        props.handlePaginationState(setting);
        setSelectedRows([]);
    }

    const handleDrawerClose = () => {
        props.changeInitialState({ showDrawer: false, formType: "", initialValues: null })
    }

    const handleSuppliersEdit = (data, type) => {
        setSupplierId(data?.id)
        props.changeInitialState({ showDrawer: true, formType: type, initialValues: {} })
    }

    return (
        <Stack spacing={2}>
            <Grid container justifyContent="space-between">
                <Grid item xs={6} sx={{ alignSelf: "center" }}>
                    <Typography variant="h6" >
                        {params?.status === "profile" ? "Profile" : `${capitalizeFirstLetter(params?.status)} Suppliers`}
                        {/* <span style={{ color: "gray", fontSize: "10px", verticalAlign: "super" }}>({0})</span> */}
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{ alignSelf: "center" }} display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
                    <SearchRecords handlePagination={handlePagination} pagination={pagination} placeholder={"Search Suppliers"} />
                </Grid>
            </Grid>

            {
                params.status === "profile" ? (
                    <Grid container gap={10} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Grid item display={"flex"} flexDirection={"row"} gap={1}>
                            <Grid item display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                                <Typography variant="subtitle2" color="textPrimary">Profile </Typography>
                                <ToggleButtonGroup
                                    color='primary'
                                    value={profileStatus}
                                    exclusive
                                    onChange={(e, val) => setProfileStatus(val ? val : "all")}
                                    aria-label="image"
                                    size='small'
                                >
                                    <ToggleButton value="all" aria-label="all" sx={{ px: 2 }}>
                                        All
                                    </ToggleButton>
                                    <ToggleButton value="with" aria-label="with profile" sx={{ px: 2 }}>
                                        With Profile
                                    </ToggleButton>
                                    <ToggleButton value="without" aria-label="without profile" sx={{ px: 2 }}>
                                        W/o Profile
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null
            }

            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        <SuppliersTableComponent
                            edit={(val, type) => handleSuppliersEdit(val, type)}
                            loading={isLoading}
                            fetching={isFetching}
                            count={suppliers?.total}
                            data={suppliers?.results || []}
                            pagination={pagination}
                            handlePagination={(val) => handlePagination(val)}
                            status={status}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <SuppliersAddDrawer params={params?.status} supplierId={supplierId} handleSuppliersEdit={handleSuppliersEdit} show={showDrawer} close={handleDrawerClose} formType={formType} initialValues={initialValues} />
        </Stack >
    );
}

export default SuppliersManagerContainer;